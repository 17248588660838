@import "_partials/_variables";
@import "_partials/_form";

::-moz-selection {background: $color-primary; color: $light; text-shadow:1px 1px 1px rgba(0,0,0,.35);}
::-webkit-selection {background: $color-primary; color: $light; text-shadow:1px 1px 1px rgba(0,0,0,.35);}
::selection {background: $color-primary; color: $light; text-shadow:1px 1px 1px rgba(0,0,0,.35);}
a {-webkit-tap-highlight-color: rgba($color-primary, 0.5);}

body {
  background: $light-gray;
  font-family: $font-custom;
  font-weight: 400;
  font-size: 16px;
  color: $text-color;
  header {
    background: none;
    .header_login {
      padding: 15px 0;
      border-bottom-width: 2px;
      margin-bottom: 0;
      p {
        text-transform: uppercase;
        font-weight: 400;
        font-size: 30px;
        color: $color-secondary;
        margin: 0;
        @media screen and (max-width: 767px) {
          width: 100%;
          text-align: center;
          font-size: 24px;
          margin-top: 10px;
        }
      }
    }
  }
  &.login {
    background: $color-tertiary;
    header {
      display: none;
    }
  }
}
.header_login {
  border-bottom: 1px solid $color-secondary;
  margin-bottom: 15px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 15px 30px;
  .logo_oney {
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    @media screen and (max-width: 767px) {
      -webkit-order: initial;
      -ms-flex-order: initial;
      order: initial;
    }
  }
}
.disconnect {
  width: 100%;
  text-align: right;
  a {
    font-size: 12px;
    color: $color-error;
    transition: 0.3s color ease-in-out;
    &:hover {
      color: darken($color-error, 15);
    }
  }
}
/** LOGIN **/
.page-login {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
  .bg-login {
    @media screen and (max-width: 991px) {
      display: none;
    }
    img {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      height: auto;
    }
  }
  .card-login {
    max-width: 475px;
    background: #fff;
    margin: 0 auto;
    padding: 0;
    border-radius: 10px;
    box-shadow: 0 12px 12px rgba(#000000, 0.16);
    @media screen and (max-width: 505px) {
      margin: 0 15px;
    }
    .content_login {
      padding: 0 30px 50px 30px;
      .btn-bottom {
        margin-top: 10px;
      }
      .btn-bottom a,
      .password-forget {
        display: block;
        text-align: center;
        color: $color-primary;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .btn-primary,
    .btn-secondary {
      font-size: 16px;
      height: 48px;
      line-height: 48px;
    }
  }
  h1 {
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 20px;
    @media screen and (max-width: 505px) {
      font-size: 24px;
    }
  }
}
/** BREADCRUMB **/
.login {
  .breadcrumb {
    display: none;
  }
}
.breadcrumb {
  background: none;
  border-bottom: 1px solid $gray;
  text-align: center;
  display: block;
  padding: 20px 0;
  margin-bottom: 15px;
  position: relative;
  h1 {
    display: inline-block;
    vertical-align: middle;
    font-weight: 600;
    font-size: 24px;
    margin: 0 auto;
  }
  .btn-back {
    position: absolute;
    left: 0;
    z-index: 1;
    @media screen and (max-width: 550px) {
      position: relative;
      left: initial;
      display: block;
      margin-bottom: 10px;
      text-align: left;
      a {
        display: inline-block;
      }
    }
    a {
      padding: 5px 10px;
      height: auto;
      line-height: normal;
    }
  }
}
h2 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
}
/** BALISAGE **/
.choix_credit {
  text-align: center;
  margin-bottom: 30px;
  a {
    color: $light;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
    display: inline-block;
    vertical-align: middle;
    border-radius: 18px;
    width: 300px;
    padding: 15px;
    height: 190px;
    margin: 0 15px;
    span {
      display: inline-block;
      vertical-align: middle;
      &.fixIt {
        width: 0;
        height: 100%;
      }
    }
    &:hover {
      text-decoration: none;
    }
    &.credit_gratuit {
      background: $color-primary;
      &:hover {
        background: lighten($color-primary, 5);
      }
    }
    &.credit_payant {
      background: $color-secondary;
      &:hover {
        background: lighten($color-secondary, 5);
      }
    }
  }
}

table {
  background: $light;
  width: 100%;
  margin-bottom: 20px;
  thead {
    border-bottom: 1px solid #BDBDBD;
    td {
      font-size: 13px;
      font-weight: 600;
    }
  }
  tr {
    & + tr {
      border-top: 1px solid $gray;
    }
  }
  td {
    padding: 10px;
    position: relative;
    &.num_prix_article {
      width: 100px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    &.num_prix_garantie {
      width: 90px;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
    input,
    select {
      display: block;
      width: 100%;
      height: 100%;
      border: 1px solid $gray;
      border-radius: 4px;
      height: 32px;
      padding: 0 10px;
      font-size: 14px;
    }
    select {
      padding: 0 5px;
      min-width: 120px;
      &[readonly] {
        background: #F0F0F0;
        color: $text-color;
        cursor: not-allowed;
        -webkit-appearance: none;        
        appearance: none;        
      }
    }
    .delete {
      color: $color-secondary;
      transition: 0.3s color ease-in-out;
      &:hover {
        color: lighten($color-secondary, 5);
      }
    }
    &.format {
      select {
        min-width: 75px;
      }
    }
    &.date_balisage {
      min-width: 163px;
      max-width: 164px;
      select {
        display: inline-block;
        vertical-align: middle;
        &:nth-child(1) {
          width: 90px;
        }
        &:nth-child(2) {
          width: 70px;
          margin-left: 5px;
        }
        &:nth-child(3) {
          width: 100%;
          display: block;
          margin-top: 5px;
        }
      }
      input {
        font-size: 14px;
        padding: 0 5px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    thead {
      display: none;
    }
    tr {
      display: block;
      padding: 10px 0;
      & + tr {
        border-top: 15px solid $light-gray;
      }
    }
    td {
      display: block;
      padding: 0 15px;
      &:before {
        content: attr(data-title);
        font-size: 12px;
        display: block;
      }
      & + td {
        margin-top: 10px;
      }
      &.date_balisage {
        max-width: 100%;
        select {
          &:nth-child(1) {
            width: 34%;
          }
          &:nth-child(2) {
            width: calc(33% - 5px);
          }
          &:nth-child(3) {
            width: calc(33% - 5px);
            display: inline-block;
            margin-top: 0;
            margin-left: 5px;
          }
        }
      }
    }
  }
}
.buttons-line {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  .btn-primary {
    width: auto;
  }
  @media screen and (max-width: 550px) {
    display: block;
    .btn-primary,
    .btn-tertiary {
      width: 100%;
      text-align: center;
      max-width: 300px;
      margin: 0 auto 15px;
    }
  }
}
/* ALERTS */
.alert {
  font-size: 12px;
  padding: 8px 15px;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.invalid-feedback {
  font-size: 11px;
  line-height: normal;
  margin: 0 0 5px;
  .form-error-icon {
    display: none;
  }
}
.form-errors {
  font-size: 12px;
  padding: 8px 15px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  &:empty {
    display: none;
  }
}

/** LOADING **/
.loader {
  position: absolute;
  left: 11px;
  right: 11px;
  top: 11px;
  bottom: 11px;
  border-radius: 3px;
  z-index: 1;
  margin: 0 auto;
  background: rgba($light, 0.8);
  text-align: left;
  padding-left: 10px;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 100%;
}
.lds-ellipsis div {
  position: absolute;
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: $color-primary;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 4px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 4px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 16px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 28px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(12px, 0);
  }
}
