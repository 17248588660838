form {
  &.no-legend {
    legend {
      display: none;
    }
  }
  fieldset {
    &.form-group {
      margin-bottom: 0;
    }
  }
}
.form-group {
  margin-bottom: 30px;
  label {
    display: block;
    margin: 0;
    font-family: $font-custom;
    font-size: 16px;
    font-weight: 400;
  }
  .select2-container--default .select2-selection--single,
  .form-control {
    height: 48px;
    border-radius: 24px;
    border-color: $gray;
    &.is-invalid {
      border-color: $color-error;
    }
  }
}
.select2-container--default .select2-selection--single {
  padding: 0.375rem 20px 0.375rem 0.75rem;
  margin-bottom: 0;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 34px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 46px;
}
.select2-container--default .select2-results__option[aria-selected="true"],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: $color-primary;
  color: #FFF;
}
input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  max-width: 160px;
  @media screen and (max-width: 991px) {
    max-width: none;
  }
}
.btn-primary {
  font-family: $font-custom;
  background: $color-primary;
  color: $light;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  display: block;
  border: 0;
  border-radius: 30px;
  height: 36px;
  line-height: 36px;
  padding: 0 20px;
  display: block;
  width: 100%;
  &:hover {
    background: lighten($color-primary, 5);
  }
  &:not(:disabled):not(.disabled):active, 
  &:not(:disabled):not(.disabled).active,
  &:focus,
  &.focus {
    background: lighten($color-primary, 5);
    box-shadow: 0 0 0 0.2rem rgba(lighten($color-primary, 5), 0.5);
  }
}
.btn-secondary {
  @extend .btn-primary;
  background: $color-secondary;
  color: $light;
  text-decoration: none;
  &:hover {
    background: lighten($color-secondary, 5);
  }
  &:not(:disabled):not(.disabled):active, 
  &:not(:disabled):not(.disabled).active,
  &:focus,
  &.focus {
    background: lighten($color-secondary, 5);
    box-shadow: 0 0 0 0.2rem rgba(lighten($color-secondary, 5), 0.5);
  }
}
.btn-tertiary {
  @extend .btn-primary;
  background: none;
  border: 1px solid $color-secondary;
  color: $color-secondary;
  width: auto;
  transition: 0.3s color ease-in-out, 0.3s background ease-in-out;
  &:not(:disabled):not(.disabled):active, 
  &:not(:disabled):not(.disabled).active,
  &:focus,
  &.focus,
  &:hover {
    text-decoration: none;
    background: $color-secondary;
    color: $light;
    box-shadow: none;
  }
}