$light: #FFF;
$light-gray: #F5F5F5;
$gray: #CCCCCC;

$color-primary: #00985F;
$color-secondary: #E0001A;
$color-tertiary: #9FCDBB;

$text-color: #212121;

$color-error: #dc3545;

$font-custom: 'Raleway', sans-serif;